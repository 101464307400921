<template>
  <!-- remove this css mt-10 pt-10 after remove warning top -->
  <div class="lg:pb-8','bg-main shop:bg-white font-eina h-full overflow-hidden rounded dark:bg-mainDark lg:pl-0 lg:pr-8 lg:pt-10">
    <TransitionRoot as="template" :show="sidebarOpen" :class="[userStore.getIsDarkMode ? 'dark' : '']">
      <DialogComponent as="div" class="fixed top-0 z-40 flex h-full lg:hidden" @close="sidebarOpen = false">
        <TransitionChild as="template" enter="transition-opacity ease-linear duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="transition-opacity ease-linear duration-300" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-600/75" />
        </TransitionChild>
        <TransitionChild
          as="template"
          enter="transition ease-in-out duration-300 transform"
          enter-from="-translate-x-full"
          enter-to="translate-x-0"
          leave="transition ease-in-out duration-300 transform"
          leave-from="translate-x-0"
          leave-to="-translate-x-full"
        >
          <div class="relative flex w-full max-w-xs flex-1 flex-col bg-main pb-4 pt-5 dark:bg-mainDark">
            <TransitionChild as="template" enter="ease-in-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in-out duration-300" leave-from="opacity-100" leave-to="opacity-0">
              <div class="absolute right-0 top-0 -mr-12 pt-2">
                <button type="button" class="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white" @click="sidebarOpen = false">
                  <XMarkIcon class="h-6 w-6 text-white" aria-hidden="true" />
                </button>
              </div>
            </TransitionChild>
            <div class="flex shrink-0 items-center px-4">
              <img class="ml-5 h-3 w-auto pr-2" :src="$filePath + '/logos/white-logo.png'" alt="Tanganica Logo" />
            </div>
            <div class="mt-5 h-0 flex-1 overflow-y-auto overflow-x-hidden overscroll-x-none">
              <MainMenu
                @sidebarOpen="
                  (value) => {
                    sidebarOpen = value;
                  }
                "
              ></MainMenu>
            </div>
          </div>
        </TransitionChild>
        <div class="w-14 shrink-0" aria-hidden="true">
          <!-- Dummy element to force sidebar to shrink to fit close icon -->
        </div>
      </DialogComponent>
    </TransitionRoot>

    <!-- Static sidebar for desktop -->
    <div class="hidden bg-main dark:bg-mainDark lg:fixed lg:inset-y-0 lg:flex lg:w-64 lg:flex-col">
      <!-- Sidebar component, swap this element with another sidebar if you like -->
      <div class="flex grow flex-col overflow-y-auto overflow-x-hidden bg-main pt-5 dark:bg-mainDark">
        <div class="flex shrink-0 items-center px-4 lg:mb-5 lg:mt-8">
          <img class="ml-5 h-5 w-auto pr-2" :src="$filePath + '/logos/white-logo.png'" alt="Tanganica Logo" />
        </div>
        <div class="flex flex-1 flex-col">
          <MainMenu></MainMenu>
        </div>
      </div>
    </div>
    <div class="flex w-full flex-1 flex-col overflow-hidden bg-main dark:bg-mainDark lg:pl-64">
      <div class="sticky top-0 z-20 flex h-auto w-screen shrink-0 bg-main p-4 dark:bg-mainDark lg:z-0 lg:hidden">
        <button type="button" class="border-r border-gray-200 px-4 text-white focus:outline-none lg:hidden" @click="sidebarOpen = true">
          <Bars3BottomLeftIcon class="h-6 w-6" aria-hidden="true" />
        </button>
        <img class="m-auto h-3 w-auto" :src="$filePath + '/logos/white-logo.png'" alt="Tanganica Logo" />
        <UserMenu></UserMenu>
      </div>

      <main class="mb-2 rounded-3xl bg-gray-100 dark:bg-[#232d45] dark:text-[#cbd5e1]">
        <TopMenu></TopMenu>
        <div class="relative rounded py-6 dark:text-[#cbd5e1]">
          <div class="mx-auto h-[80vh] w-auto overflow-auto rounded-lg px-4 dark:text-[#cbd5e1] lg:px-4">
            <router-view></router-view>
          </div>
        </div>
      </main>
    </div>
  </div>
  <!-- WELCOME MODAL -->
  <Dialog v-model:visible="isWelcomeModalOpen" modal :closable="false" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="$t('layout.client.clientLayout.welcomeModal.title')"></h1>
    </template>
    <template #default>
      <p class="dialog-description text-main" v-html="$t('layout.client.clientLayout.welcomeModal.subtitle')"></p>
      <div class="relative mt-10 h-20">
        <div class="absolute inset-0">
          <Steps :dashboardStatus="status" />
        </div>
      </div>
      <div>
        <p class="mx-auto mt-5 max-w-xl text-center text-main" v-html="$t('layout.client.clientLayout.welcomeModal.description')"></p>
      </div>
    </template>
    <template #footer>
      <div class="mx-auto mt-5 flex flex-col items-center justify-between gap-y-4 md:w-[30rem] md:flex-row md:gap-x-4 md:gap-y-0">
        <template v-if="userStore.getTheme === ThemeEnum.DEFAULT">
          <MainButton
            @click="continueToCredit()"
            class="mx-auto bg-gradient-to-r from-[#FF9900] to-[#FF7294] text-xs font-bold hover:from-[#FF7294] hover:to-[#FF9900] md:text-sm"
            :hover="false"
            type="button"
            :text="$t('layout.client.clientLayout.welcomeModal.buttonCredit')"
          ></MainButton>
        </template>
        <div v-else class="mx-auto w-full">
          <MainButton @click="isWelcomeModalOpen = false" class="text-xs font-bold hover:bg-mainhover md:w-48 md:text-sm" type="button" :text="$t('layout.client.clientLayout.welcomeModal.buttonAplication')" :hover="false"></MainButton>
        </div>
      </div>
    </template>
  </Dialog>
  <!-- Subscription Dialog -->
  <Dialog v-model:visible="openSubscriptionDialog" modal :closable="true" :draggable="false">
    <template #header>
      <h1 class="dialog-header" v-html="$t('subscription.dialogHeader')"></h1>
    </template>
    <template #default>
      <div class="pt-5">
        <p class="text-center text-gray-500">{{ $t('subscription.dialogBody') }}</p>
      </div>
    </template>
    <template #footer>
      <router-link @click="openSubscriptionDialog = false" :to="{ name: CLIENT_ROUTES.SUBSCRIPTION_PAGE }" class="mx-auto mt-5 block w-fit rounded-full bg-[#FF914D] px-7 py-1 text-lg text-white shadow hover:scale-110">
        <span class="font-bold text-white">{{ $t('subscription.dialogButton') }}</span>
      </router-link>
    </template>
  </Dialog>
  <!-- Information Dialog -->
  <Dialog class="lg:!w-[60rem]" v-model:visible="openInformationDialog" modal :closable="true" :draggable="false" @after-hide="hideDialog()">
    <template #header>
      <h1 class="dialog-header">{{ $t('mainInformationDialog.title') }}</h1>
    </template>
    <div class="flex flex-col items-start text-left text-main">
      <p class="dialog-description text-justify text-main">{{ $t('mainInformationDialog.text1') }}</p>
      <p class="dialog-description mt-3 text-justify text-main" v-html="$t('mainInformationDialog.text2')"></p>
      <p class="dialog-description mt-3 text-justify text-main" v-html="$t('mainInformationDialog.text3')"></p>
      <p class="dialog-description mt-3 text-justify text-main">{{ $t('mainInformationDialog.text4') }}</p>
      <p class="mt-3 text-justify text-lg font-bold text-main">{{ $t('mainInformationDialog.text5') }}</p>
      <p class="dialog-description text-justify text-main">{{ $t('mainInformationDialog.text6') }}</p>
      <p class="dialog-description text-justify text-main">{{ $t('mainInformationDialog.text7') }}</p>
      <p class="dialog-description text-justify text-main">{{ $t('mainInformationDialog.text8') }}</p>
      <p class="dialog-description text-justify text-main">{{ $t('mainInformationDialog.text9') }}</p>
      <p class="dialog-description text-justify text-main">{{ $t('mainInformationDialog.text10') }}</p>
      <p class="dialog-description mt-5 text-justify text-main">{{ $t('mainInformationDialog.text11') }}</p>
      <p class="dialog-description text-justify text-main">{{ $t('mainInformationDialog.text12') }}</p>
    </div>
    <template #footer> </template>
  </Dialog>
</template>

<script setup lang="ts">
import { Dialog as DialogComponent, DialogOverlay, TransitionChild, TransitionRoot } from '@headlessui/vue'; // TODO replace with primevue dialog
import { Bars3BottomLeftIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import Dialog from 'primevue/dialog';
import { useToast } from 'primevue/usetoast';
import { ref, onMounted, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { ThemeEnum } from '@/enums/ThemeEnum';
import MainMenu from '@/layout/components/MainMenu.vue';
import TopMenu from '@/layout/components/TopMenu.vue';
import UserMenu from '@/layout/components/UserMenu.vue';
import Steps from '@/modules/dashboard/components/Steps.vue';
import { api } from '@/services/api';
import { useEshopsStore } from '@/stores/eshops';
import { useUserStore } from '@/stores/user';
import { useWizardStore } from '@/stores/wizard';

const router = useRouter();
const userStore = useUserStore();
const wizardStore = useWizardStore();
const eshopsStore = useEshopsStore();
const toast = useToast();
const { t } = useI18n();

const sidebarOpen = ref(false);
const isWelcomeModalOpen = ref(false);
const openSubscriptionDialog = ref(false);
const openInformationDialog = ref(userStore.openFirstLoadDialog);

const status = ref();

onMounted(async () => {
  // Iframe hacks
  if (userStore.getTheme === ThemeEnum.DEFAULT) {
    try {
      const listOfEshopsResponse = await api.clientEshopListGetEshops({
        metadata: {
          accessToken: 'user',
        },
      });

      eshopsStore.setManagedEshops(listOfEshopsResponse.data.eshops);
      const selectedEshop = eshopsStore.selectedEshop;
      listOfEshopsResponse.data.eshops.forEach((eshop) => {
        if (eshop.id === selectedEshop?.id) {
          eshopsStore.setSelectedEshop(eshop);
        }
      });
      await getEshopStatus();
      showWelcomeModal();
    } catch (error: any) {
      if (error.response) {
        if (error.response.data.status >= 500) {
          toast.add({
            severity: 'error',
            summary: t('serverErrorTitle'),
            detail: error.response.data.requestId,
            life: 20000,
          });
        } else {
          toast.add({
            severity: 'error',
            summary: error.response.data.detail,
            life: 6000,
          });
        }
      } else {
        console.warn(error);
      }
    }
  }
});

watch(
  () => userStore.openSubscriptionDialog,
  async () => {
    openSubscriptionDialog.value = true;
  }
);

const showWelcomeModal = () => {
  if (wizardStore.isWizardFinished) {
    confetti();
    isWelcomeModalOpen.value = true;
    wizardStore.isWizardFinished = false;
  }
};

const continueToCredit = () => {
  isWelcomeModalOpen.value = false;
  router.push({ name: CLIENT_ROUTES.RECHARGE_CREDIT_PAGE });
};

const hideDialog = () => {
  openInformationDialog.value = false;
  userStore.openFirstLoadDialog = false;
};

const getEshopStatus = async () => {
  try {
    const { data } = await api.clientDashboardGetStatus();
    status.value = data.status;
  } catch (error: any) {
    if (error.response) {
      if (error.response.data.status >= 500) {
        toast.add({
          severity: 'error',
          summary: t('serverErrorTitle'),
          detail: error.response.data.requestId,
          life: 20000,
        });
      } else {
        toast.add({
          severity: 'error',
          summary: error.response.data.detail,
          life: 6000,
        });
      }
    } else {
      console.warn(error);
    }
  }
};

function confetti() {
  const numberOfConfetti = 200; // number of confetti
  const confettiColors = ['#EF5350', '#EC407A', '#AB47BC', '#7E57C2', '#5C6BC0', '#42A5F5', '#29B6F6', '#26C6DA', '#26A69A', '#66BB6A', '#9CCC65', '#D4E157', '#FFEE58', '#FFCA28', '#FFA726', '#FF7043']; // colors confetti can be

  const container = document.createElement('div');
  document.body.appendChild(container);

  // Create confetti
  for (let i = 0; i < numberOfConfetti; i++) {
    const confetto = document.createElement('div');
    confetto.style.backgroundColor = confettiColors[Math.floor(Math.random() * confettiColors.length)] ?? '#EF5350';
    confetto.style.position = 'fixed';
    confetto.style.zIndex = '9999';
    confetto.style.width = `${Math.random() * 15 + 5}px`;
    confetto.style.height = `${Math.random() * 15 + 5}px`;
    confetto.style.top = `${-Math.random() * 20}%`;
    confetto.style.left = `${Math.random() * 100}%`;
    confetto.style.opacity = String(Math.random());
    confetto.style.transform = `rotate(${Math.random() * 360}deg)`;

    // Add animation
    confetto.style.transition = `top 5s linear ${Math.random() * 5}s, left 5s linear ${Math.random() * 5}s`;
    container.appendChild(confetto);
  }

  // Start animation
  setTimeout(() => {
    for (const confetto of container.children) {
      confetto.style.top = `${Math.random() * 100 + 100}%`;
      confetto.style.left = `${Math.random() * 100 - 50}%`;
    }
  });

  // Vymaže konfety po 5 vteřinách
  setTimeout(() => {
    document.body.removeChild(container);
  }, 10000);
}
</script>
