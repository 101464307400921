import { NewspaperIcon, PhotoIcon, QueueListIcon } from '@heroicons/vue/24/outline';
import { defineStore } from 'pinia';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { IStepOne } from '@/modules/campaigns/interfaces/microsoft/PRXWizard/IStepOne';
import { IStepThree } from '@/modules/campaigns/interfaces/microsoft/PRXWizard/IStepThree';
import { IStepTwo } from '@/modules/campaigns/interfaces/microsoft/PRXWizard/IStepTwo';

type step = [IStepOne, IStepTwo, IStepThree];

interface State {
  formatStatus: boolean;
  currentStep: number;
  jsonFileName: string;
  steps: step;
  isDirty: boolean;
}

export const useMicrosoftBING_PRXWizardStore = defineStore('microsoftBING_PRXwizard', {
  state: (): State => ({
    formatStatus: false,
    currentStep: 0,
    jsonFileName: '',
    steps: [
      {
        title: 'campaigns.google.prx.editDialogAssets',
        icon: PhotoIcon,
        route: {
          name: CAMPAIGNS_ROUTES.EDIT_STEP,
          params: {
            step: 'assets', // Name of the component
          },
        },
        isValid: false,
        values: {
          logos: [],
          images: [],
        },
      },
      {
        title: 'campaigns.google.prx.editDialogHeadline',
        icon: QueueListIcon,
        route: {
          name: CAMPAIGNS_ROUTES.EDIT_STEP,
          params: {
            step: 'headlines', // Name of the component
          },
        },
        isValid: false,
        values: {
          Headline1: null,
          Headline2: null,
          Headline3: null,
          Headline4: null,
          Headline5: null,
          Headline6: null,
          Headline7: null,
          Headline8: null,
          Headline9: null,
          Headline10: null,
          Headline11: null,
          Headline12: null,
          Headline13: null,
          Headline14: null,
          Headline15: null,
          LongHeadline1: null,
          LongHeadline2: null,
          LongHeadline3: null,
          LongHeadline4: null,
          LongHeadline5: null,
        },
      },
      {
        title: 'campaigns.google.prx.editDialogDescription',
        icon: NewspaperIcon,
        route: {
          name: CAMPAIGNS_ROUTES.EDIT_STEP,
          params: {
            step: 'descriptions', // Name of the component
          },
        },
        isValid: false,
        values: {
          Description1: null,
          Description2: null,
          Description3: null,
          Description4: null,
          Description5: null,
        },
      },
    ],
    isDirty: false,
  }),
  getters: {
    getCurrentStep(): number {
      return this.currentStep;
    },
    getStepOne(): IStepOne {
      return this.steps[0];
    },
    getStepTwo(): IStepTwo {
      return this.steps[1];
    },
    getStepThree(): IStepThree {
      return this.steps[2];
    },
  },
  actions: {
    resetStepsValidity() {
      for (let i = this.currentStep; i < this.steps.length; i++) {
        if (this.steps[i]) {
          this.steps[i].isValid = false;
        }
      }
    },
    reset() {
      this.$reset();
    },
  },
});
