import { RouteRecordRaw } from 'vue-router';
import { CLIENT_ROUTES } from '@/enums/Routes/ClientRoutesEnum';
import { CampaignEnum } from '@/modules/campaigns/enums/CampaignEnum';
import { EditModeEnum } from '@/modules/campaigns/enums/EditModeEnum';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import CampaignEditPage from '@/modules/campaigns/pages/CampaignEdit.vue';
import CampaignFormatsPage from '@/modules/campaigns/pages/CampaignFormats.vue';
import CampaignsPage from '@/modules/campaigns/pages/Index.vue';
import Step from '@/modules/campaigns/pages/Step.vue';
import { loadWizardStore } from '@/modules/campaigns/utils/loadWizardStore';
import { EnumsMarketingFormat } from '../../../../generated/api';

function isValidCampaign(campaign: string): boolean {
  return Object.values(CampaignEnum).includes(campaign as CampaignEnum);
}

function isValidFormat(format: string): boolean {
  return Object.values(EnumsMarketingFormat).includes(format as EnumsMarketingFormat);
}

async function canGoToWizardMode(format: string, campaign: string): Promise<boolean> {
  const store = await loadWizardStore(campaign as CampaignEnum, format as EnumsMarketingFormat);
  return !store.formatStatus // Disable the wizard mode if the format is already created
}

const routes: Array<RouteRecordRaw> = [
  {
    path: 'campaigns',
    name: CAMPAIGNS_ROUTES.INDEX,
    component: CampaignsPage,
    redirect: () => ({ name: CAMPAIGNS_ROUTES.FORMATS, params: { campaign: CampaignEnum.GOOGLE } }),
    children: [
      {
        path: ':campaign/formats',
        name: CAMPAIGNS_ROUTES.FORMATS,
        component: CampaignFormatsPage,
        props: (route) => ({
          campaign: route.params.campaign as CampaignEnum,
        }),
        beforeEnter: (to, from, next) => {
          if (isValidCampaign(to.params.campaign as CampaignEnum)) {
            next();
          } else {
            next({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
          }
        },
      },
      {
        path: ':campaign/:format/edit',
        name: CAMPAIGNS_ROUTES.EDIT,
        props: (route) => ({
          campaign: route.params.campaign as CampaignEnum,
          format: route.params.format as EnumsMarketingFormat,
          editMode: route.query.editMode as EditModeEnum,
        }),
        component: CampaignEditPage,
        beforeEnter: async (to, from, next) => {
          const canGoToWizard = await canGoToWizardMode(to.params.format as EnumsMarketingFormat, to.params.campaign as CampaignEnum);

          if (isValidCampaign(to.params.campaign as CampaignEnum) && isValidFormat(to.params.format as EnumsMarketingFormat)) {
            if (to.params.editMode === EditModeEnum.WIZARD && canGoToWizard) {
                next();
            } else if (to.params.editMode === EditModeEnum.WIZARD && !canGoToWizard) {
                next({ name: CAMPAIGNS_ROUTES.INDEX });
            } else {
                next();
            }
          } else {
            next({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
          }
        },
        children: [
          {
            path: ':step',
            name: CAMPAIGNS_ROUTES.EDIT_STEP,
            component: Step,
            props: (route) => {
              const props: Record<string, any> = {
                campaign: route.params.campaign as CampaignEnum,
                format: route.params.format as EnumsMarketingFormat,
                step: route.params.step,
                editMode: route.query.editMode as EditModeEnum,
              };

              return props;
            },
            beforeEnter: (to, from, next) => {
              if (isValidCampaign(to.params.campaign as CampaignEnum) && isValidFormat(to.params.format as EnumsMarketingFormat)) {
                next();
              } else {
                next({ name: CLIENT_ROUTES.DASHBOARD_PAGE });
              }
            },
          },
        ],
      },
    ],
  },
];

export default routes;
