import { defineStore } from 'pinia';
import { CAMPAIGNS_ROUTES } from '@/modules/campaigns/enums/RoutesEnum';
import { IStepOne } from '@/modules/campaigns/interfaces/microsoft/DSAWizard/IStepOne';

type step = [IStepOne];

interface State {
  formatStatus: boolean;
  currentStep: number;
  jsonFileName: string;
  steps: step;
  isDirty: boolean;
}

export const useMicrosoftBING_DSAWizardStore = defineStore('microsoftBING_DSAwizard', {
  state: (): State => ({
    formatStatus: false,
    currentStep: 0,
    jsonFileName: '',
    steps: [
      {
        route: {
          name: CAMPAIGNS_ROUTES.EDIT_STEP,
          params: {
            step: 'descriptions',
          },
        },
        isValid: false,
        values: {
          Text: null,
          TextPart2: null,
        },
      },
    ],
    isDirty: false,
  }),
  getters: {
    getCurrentStep(): number {
      return this.currentStep;
    },
    getStepOne(): IStepOne {
      return this.steps[0];
    },
  },
  actions: {
    resetStepsValidity() {
      for (let i = this.currentStep; i < this.steps.length; i++) {
        if (this.steps[i]) {
          this.steps[i].isValid = false;
        }
      }
    },
    reset() {
      this.$reset();
    },
  },
});
